import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import './CommunityNotes.css';

const recentURL = 'https://raw.githubusercontent.com/AlexMahadevan/tracking-elon/main/notebooks/weekly_data/recent_period_2025-03-11_to_2025-03-25.csv';
const earlierURL = 'https://raw.githubusercontent.com/AlexMahadevan/tracking-elon/main/notebooks/weekly_data/earlier_period_2025-02-03_to_2025-02-14.csv';

const trackedTweetIds = [
  "1904618817986474240", "1904222280655286776", "1904160507952529616", "1902411743428514085",
  "1902361782255063090", "1902131566521795054", "1902003410255176021", "1901964313511731387",
  "1901631931298525519", "1901441626116870156", "1901317322041597987", "1900608417515081812",
  "1899905798593274270", "1900670438260257276", "1899980394604175778", "1899561031996985814",
  "1901406082800459809", "1901011700343861315", "1900741103424286723", "1904362652987429338",
  "1903215026682380513", "1902818394946277485", "1899637674241048800", "1899636898533867969",
  "1889386802307539405",
];

const CommunityNotes = () => {
  const [recent, setRecent] = useState([]);
  const [earlier, setEarlier] = useState([]);

  const loadCSV = (url, setter) => {
    Papa.parse(url, {
      download: true,
      header: true,
      dynamicTyping: true,
      complete: (results) => setter(results.data.filter(row => row.noteId)) // filter empty rows
    });
  };

  useEffect(() => {
    loadCSV(recentURL, setRecent);
    loadCSV(earlierURL, setEarlier);
  }, []);

  const calcMetrics = (data, label) => {
    const helpfulNotes = data.filter(d => d.currentStatus === "CURRENTLY_RATED_HELPFUL");
    const allRatio = helpfulNotes.map(d => parseFloat(d.helpfulness_ratio || 0));
    const avgHelpfulnessRatio = (
      allRatio.reduce((a, b) => a + b, 0) / (allRatio.length || 1)
    ).toFixed(2);

    const grouped = data.reduce((acc, d) => {
      const id = d.tweetId;
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    const notesPerTweet = (
      Object.values(grouped).reduce((a, b) => a + b, 0) / (Object.keys(grouped).length || 1)
    ).toFixed(2);

    const trackedNotes = data.filter(d => trackedTweetIds.includes(String(d.tweetId)));
    const trackedHelpful = trackedNotes.filter(d => d.currentStatus === "CURRENTLY_RATED_HELPFUL");
    const trackedRatios = trackedHelpful.map(d => parseFloat(d.helpfulness_ratio || 0));
    const trackedRatio = (
      trackedRatios.reduce((a, b) => a + b, 0) / (trackedRatios.length || 1)
    ).toFixed(2);

    const trackedNotesPerTweet = (
      trackedNotes.length / (new Set(trackedNotes.map(d => d.tweetId)).size || 1)
    ).toFixed(2);

    const helpfulPercent = ((helpfulNotes.length / data.length) * 100).toFixed(1);
    const trackedHelpPercent = ((trackedHelpful.length / (trackedNotes.length || 1)) * 100).toFixed(1);

    return {
      label,
      avgHelpfulnessRatio,
      notesPerTweet,
      trackedRatio,
      trackedNotesPerTweet,
      helpfulPercent,
      trackedHelpPercent
    };
  };

  const recentMetrics = calcMetrics(recent, "Recent");
  const earlierMetrics = calcMetrics(earlier, "Earlier");
  const comparison = [earlierMetrics, recentMetrics];

  const sortedRecent = [...recent].sort((a, b) => (b.total_helpful || 0) - (a.total_helpful || 0));

  return (
    <div className="community-notes">
      <h1>Community Notes: A look at the data</h1>
      <p>In an effort to track how often Donald Trump, Elon Musk and other... people... are fact-checked by X's Community Notes, we're overall activity compared to notes on their posts. We're also looking at the number of helpful notes before and after Musk said he would "fix" Community Notes because it was flagging misinformation spread by his colleagues. "Tracked" refers to Notes on posts from these individuals.</p>

      <div className="big-numbers">
        <div>
          <h3>% Helpful Notes (after "the fix")</h3>
          <p>{recentMetrics.helpfulPercent}%</p>
        </div>
        <div>
          <h3>% Helpful Notes (before "the fix")</h3>
          <p>{earlierMetrics.helpfulPercent}%</p>
        </div>
        <div>
          <h3>Notes per Tweet (after "the fix")</h3>
          <p>{recentMetrics.notesPerTweet}</p>
        </div>
        <div>
          <h3>Notes per Tweet (before "the fix")</h3>
          <p>{earlierMetrics.notesPerTweet}</p>
        </div>
        <div>
          <h3>% Helpful tracked (after "the fix")</h3>
          <p>{recentMetrics.trackedHelpPercent}%</p>
        </div>
        <div>
          <h3>% Helpful tracked (before "the fix")</h3>
          <p>{earlierMetrics.trackedHelpPercent}%</p>
        </div>
      </div>

      <table className="metrics-table">
        <thead>
          <tr>
            <th>Period</th>
            <th>Helpfulness Ratio (All)</th>
            <th>Notes per Tweet (All)</th>
            <th>Helpfulness Ratio (Tracked)</th>
            <th>Notes per Tweet (Tracked)</th>
          </tr>
        </thead>
        <tbody>
          {comparison.map((row, i) => (
            <tr key={i}>
              <td>{row.label}</td>
              <td>{row.avgHelpfulnessRatio}</td>
              <td>{row.notesPerTweet}</td>
              <td>{row.trackedRatio}</td>
              <td>{row.trackedNotesPerTweet}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Top Recent Notes by Helpful Votes</h2>
      <table className="notes-table">
        <thead>
          <tr>
            <th>Tweet ID (Linked!)</th>
            <th>Helpful Votes</th>
            <th>Helpfulness Ratio</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {sortedRecent.slice(0, 10).map((d, i) => (
            <tr key={i}>
              <td>
                <a href={`https://x.com/anyuser/status/${d.tweetId}`} target="_blank" rel="noreferrer">
                  {d.tweetId}
                </a>
              </td>
              <td>{d.total_helpful}</td>
              <td>{parseFloat(d.helpfulness_ratio || 0).toFixed(2)}</td>
              <td>{d.summary}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Recent Tracked Notes</h2>
      <table className="notes-table">
        <thead>
          <tr>
            <th>Tweet ID (Linked!)</th>
            <th>Helpful Votes</th>
            <th>Helpfulness Ratio</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {recent
            .filter((d) => trackedTweetIds.includes(String(d.tweetId)))
            .sort((a, b) => (b.total_helpful || 0) - (a.total_helpful || 0))
            .slice(0, 10)
            .map((d, i) => (
              <tr key={i}>
                <td>
                  <a href={`https://x.com/anyuser/status/${d.tweetId}`} target="_blank" rel="noreferrer">
                    {d.tweetId}
                  </a>
                </td>
                <td>{d.total_helpful}</td>
                <td>{parseFloat(d.helpfulness_ratio || 0).toFixed(2)}</td>
                <td>{d.summary}</td>
              </tr>
            ))}
        </tbody>
      </table>

    </div>
  );
};

export default CommunityNotes;
