import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import fm from 'front-matter';
import rehypeRaw from 'rehype-raw'; // Allows raw HTML rendering
import { marked } from 'marked'; // Markdown parser
import styles from '../styles/Blog.module.css';

function Blog() {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedPost, setExpandedPost] = useState(null); // Track expanded state for posts

  const postFiles = ['/posts/post4.md', 'posts/cn-meta.md', '/posts/post1.md', '/posts/post2.md', '/posts/post3.md'];

  useEffect(() => {
    const fetchPosts = async () => {
      const fetchedPosts = await Promise.all(
        postFiles.map(async (file) => {
          const response = await fetch(file);
          const text = await response.text();
          const { attributes, body } = fm(text);
          return { attributes, body };
        })
      );
      setPosts(fetchedPosts);
    };
    fetchPosts();
  }, []);

  // Function to safely truncate Markdown content
  const truncateMarkdown = (content, length) => {
    const plainText = marked(content).replace(/<[^>]*>/g, ''); // Convert to plain text
    return plainText.length > length ? `${plainText.substring(0, length)}...` : plainText;
  };

  return (
    <div className={styles.blogContainer}>
      <h1 className={styles.title}>Some writing</h1>
      <p className={styles.description}>
        Some random thoughts and Bad Writing with links to my work and Edited Writing. (Listen, I know this page looks godawful. I am building this whole thing from scratch in an attempt to learn React.)
      </p>

      {/* Search Bar */}
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Find a post"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchBar}
        />
      </div>

      {/* Blog Posts */}
      <div className={styles.postsContainer}>
        {posts
          .filter((post) =>
            post.attributes.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((post, index) => (
            <div key={index} className={styles.post}>
              <h2 className={styles.postTitle}>{post.attributes.title}</h2>
              <p className={styles.dek}>{post.attributes.dek}</p>
              <p className={styles.date}>{post.attributes.date}</p>
              {post.attributes.image && (
                <img
                  src={post.attributes.image}
                  alt={`Image for ${post.attributes.title}`}
                  className={styles.postImage}
                />
              )}
              {expandedPost === index ? (
                <>
                  {/* Render full content */}
                  <ReactMarkdown
                    className={styles.content}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {post.body}
                  </ReactMarkdown>
                  <button
                    onClick={() => setExpandedPost(null)}
                    className={styles.toggleButton}
                  >
                    Hide
                  </button>
                </>
              ) : (
                <>
                  {/* Render preview safely */}
                  <p className={styles.contentPreview}>
                    {truncateMarkdown(post.body, 150)}
                  </p>
                  <button
                    onClick={() => setExpandedPost(index)}
                    className={styles.toggleButton}
                  >
                    Read it
                  </button>
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default Blog;
