import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Training from './pages/Training';
import Projects from './pages/Projects';
import CommunityNotes from './pages/CommunityNotes';
import './styles/GlobalStyles.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/training" element={<Training />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/community-notes" element={<CommunityNotes />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;